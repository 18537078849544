.calendar-root {
    /* width: 320px; */
    /* height: 368px; */
    display: flex;
    /* background-color: slategrey; */
}
.calendar-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* background-color: slategray; */
}
.calendar-header {
    flex: 1;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    /* background-color: seashell; */
}
.calendar-header .button{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
}
.calendar-header .button .icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: "Cycloid-Font";
    color: #ffffff;
  }
.calendar-header .label{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: lightsteelblue; */
}
.calendar-header .label span{
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
    user-select: none;
}
.calendar-box {
    flex: 1;
    height: 318px;
    display: flex;
    /* background-color: slategray; */
}
.calendar-column {
    flex: 1;
    padding: 0px 1px;
    display: flex;
    flex-direction: column;
}
.calendar-column .label{
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calendar-column .label span{
    color: #999999;
    font-size: 10px;
}
.calendar-column .days {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: seashell; */
}

@font-face {
    font-family: "Cycloid-Font";
    src: local("CycloidFont"),
      url("../Resources/Fonts/cycloid-font.ttf") format("truetype");
    font-weight: normal;
  }
  