.timer-root {
  width: 260px;
  max-height: 290px;
  overflow-y: auto;
}
.timer-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.timer-root::-webkit-scrollbar {
  position: absolute;
  width: 10px;
}
.timer-root::-webkit-scrollbar-track {
  background-color: transparent;
  background-color: #343434;
  border-radius: 100px;
}
.timer-root::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 100px;
}

@font-face {
  font-family: "Cycloid-Font";
  src: local("CycloidFont"),
    url("../Resources/Fonts/cycloid-font.ttf") format("truetype");
  font-weight: normal;
}
