.timebutton-root {
  width: 53px;
  height: 53px;
  display: flex;
  margin: 4px;
}
.timebutton-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.timebutton-box span {
  color: #ffffff;
  font-size: 14px;
  user-select: none;
}
