.schedule-root {

  display: flex;
  position: relative;
  /* background-color: slategrey; */
}
.schedule-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* background-color: slategray; */
}
.schedule-header {
  flex: 1;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  /* background-color: seashell; */
}
.schedule-header .button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
}
.schedule-header .button .icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "Cycloid-Font";
  color: #ffffff;
}
.schedule-header .button .label {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #ffffff;
}

.schedule-detail {
  flex: 1;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  /* background-color: lightblue; */
}
.schedule-detail span {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
}
.schedule-detail .icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.schedule-detail .icon span {
  font-size: 40px;
  font-family: "Cycloid-Font";
  color: #ffffff;
}

.schedule-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule-box .row {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schedule-box .row .icon {
  width: 20px;
  height: 20px;
  display: flex;
  margin: 7px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.schedule-box .row .icon span {
  font-size: 40px;
  font-family: "Cycloid-Font";
  color: #ffffff;
}
.schedule-box .row .label {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #ffffff;
}
.schedule-box .row .label {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #ffffff;
}

.schedule-box .button {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.schedule-box .button span {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

@font-face {
  font-family: "Cycloid-Font";
  src: local("CycloidFont"),
    url("../Resources/Fonts/cycloid-font.ttf") format("truetype");
  font-weight: normal;
}
