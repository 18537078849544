.daybutton-root {
    width: 38px;
    height: 38px;
    display: flex;
    margin: 8px 0px;
  }
  .daybutton-box {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .daybutton-box span {
    color: #ffffff;
    font-size: 16px;
    user-select: none;
  }
  