@font-face {
  font-family: "Cycloid-Font";
  src: local("CycloidFont"),
    url("./assets/fonts/cycloid-font.ttf") format("truetype");
  font-weight: normal;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}


body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ::-webkit-scrollbar {
  display: none;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

@media (min-width: 320px) and (max-width: 600px) {
  .slick-slider.carrousel .slick-slide {
    height: 240px !important;
  }

  .slick-slider.carrousel .slick-slide div {
    height: 240px !important;
  }

  .slick-slider.carrousel .slick-list {
    height: 240px !important;
  }
}

.slick-slider.carrousel {
  width: 100%;
}

.slick-slider.carrousel .slick-slide {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.slick-slider.carrousel .slick-list {
  height: 500px;
}

.slick-slider.carrousel .slick-slide div {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.slick-slider.carrousel .slick-slide img {
  height: 100%;
  position: relative;
}

.slick-slider.carrousel .slick-dots {
  bottom: -32px;
}

.slick-slider.carrousel .slick-dots li {
  margin: 0;
}

.slick-slider.carrousel .slick-dots li button:before {
  color: #ffffff;
  opacity: 1;
}

.slick-slider.carrousel .slick-dots li.slick-active button:before {
  color: #ff0022;
  opacity: 1;
}

.slick-slider.more-recent-slider {
  width: 100%;
}

.slick-slider.more-recent-slider .slick-slide {
  width: 380px !important;
}

.slick-slider.more-recent-slider .slick-track {
  display: flex;
  flex-direction: row;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0px !important;
}
input[type="time"]::-webkit-time-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
