.schedule-confirm-root {
    width: 280px;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    border: solid 1px #444;
    margin-top: 20px;
    background-color: rgb(38, 38, 38);
}

.schedule-confirm-root .row {
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.schedule-confirm-root .row .icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .schedule-confirm-root .row .icon span {
    font-size: 48px;
    font-family: "Cycloid-Font";
    color: #ffffff;
  }
  .schedule-confirm-root .row .label {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-left: 7px;
    color: #ffffff;
  }
  .schedule-confirm-root .row .label-bold {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }